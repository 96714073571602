import React, { useContext, useEffect, useState, } from 'react'
import { Button, Col, Dropdown, Empty, Input, Layout, Progress, Row, message } from 'antd';
import { storage } from '../../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import {
    IoMdAdd, FaRegCalendarAlt, LuBadgeHelp, IoMdOpen, formatDate, formatDateShort, IoMdSettings, IoArrowRedoSharp,
    FaRegCopy, TiThMenu, CgOptions, TbExternalLink, PiLinkBold, FaLock, FaUnlockAlt, HiLockClosed,
    IoMdArrowRoundForward, FiCopy, load, PiMonitorPlayBold,
    HiLockOpen, MdOutlineTimeline, MdDevices, MdBubbleChart,
    images,
    images_modules,
    decoration,
    TiUserAddOutline,
    IoCloseSharp,
    IoAddCircleOutline,
    BiHide,
    BiShow,
    FaRegTrashCan,
    GoShare,
    RiUserAddLine,
    GoShareAndroid,
    FiShare2,
    SlArrowRight,
    RiArrowRightSLine,
    IoMdLink,
    BsFillGridFill,
    BsGrid,
    RxGrid,
    RiListUnordered,
    HiMiniArrowUpRight
} from '../../helpers'
import { Link } from 'react-router-dom';
import { useInvitation } from '../../hooks';
import { getAllInvitations, getAllUserInvitations, getAllUserTags, getInvitationbyID } from '../../services';
import { appContext } from '../../context';
import { Login } from '../../components/land-page/Login';
import { CustomButton } from '../../components/CustomButton';
import { NewInvitationDrawer } from '../../components/NewInvitationDrawer';
import { FooterMobile, HeaderBuild } from '../Build';
import justWeb from '../../assets/just_web.svg'
import { useLocation } from 'react-router-dom';
import { FaLessThanEqual, FaLink, FaListUl, FaUserPlus, FaWhatsapp } from 'react-icons/fa';
import { addShare, deleteShare, getGuestsByInvitationID } from '../../services/apiGuests';

const { Content } = Layout;
const items = [
    {
        key: '1',
        label: (
            <a >
                Eliminar Invitacion
            </a>
        ),
    },

];

const desginCards = [
    {
        icon: <MdBubbleChart />,
        title: "Personalización Total",
        text: "Elige entre una amplia variedad de paletas de colores y tipografías, y ajusta cada elemento de la invitación para reflejar perfectamente el tema de tu evento.",
        image: images.itinerary,
    },
    {
        icon: <MdDevices />,
        title: "Flexibilidad en el Diseño",
        text: "Reorganiza los elementos a tu gusto, cambia imágenes, colores, y más. La libertad creativa está en tus manos para hacer que cada invitación sea única.",
        image: images.gallery,
    },
    {
        icon: <MdOutlineTimeline />,
        title: "Previsualización en Tiempo Real",
        text: "Ve cómo se verá tu invitación a medida que la diseñas. Con la previsualización en tiempo real, puedes hacer ajustes instantáneos y asegurarte de que todo esté perfecto antes de enviarla.",
        image: images.settings,
    }
]

const baseProd = "https://www.iattend.mx"
const baseDev = "http://localhost:3000";

export const ContentInvitations = ({ setMode, setCurrentInvitation, mode, saved, setOpenLogin, setSaved, handleQRO }) => {

    // getAllInvitations
    const { response, loading, error, operation } = useInvitation()
    const [myInvitations, setMyInvitations] = useState(null)
    const [visible, setVisible] = useState(false)
    const { logged, user, setOnDate, date } = useContext(appContext)
    const [loader, setLoader] = useState(false)
    const [currentPosition, setCurrentPosition] = useState(0)
    const [isVisible, setIsVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const { pathname } = useLocation();
    const [onShare, setOnShare] = useState(false)
    const [newShared, setNewShared] = useState(null)
    const [sharedWith, setSharedWith] = useState([])
    const [onViewPasswords, setOnViewPasswords] = useState(false)
    const [invitationID, setinvitationID] = useState(null)
    const [onGrid, setOnGrid] = useState(false)



    const getTimeUntilFutureDate = (futureDate) => {
        const currentDate = new Date();
        const targetDate = new Date(futureDate);

        // Calcular la diferencia en milisegundos
        const diffInMilliseconds = targetDate - currentDate;

        // Calcular la diferencia en días
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

        if (diffInDays > 3) {
            return `${diffInDays} días`;
        } else {
            // Calcular la diferencia en horas si faltan 3 días o menos
            const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
            return `${diffInHours} horas`;
        }
    }

    const refreshInvitations = () => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        }

    }

    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Link copiado')
        } catch (err) {
            console.error('Error al copiar el texto: ', err);
        }
    };

    const handleMoode = (mode, id) => {
        setMode(mode)
        setCurrentInvitation(id)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    useEffect(() => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        } else {
            setLoader(false)
        }

    }, [])

    useEffect(() => {
        if (logged && user) {
            user.uid != "66a57fd93d724e3f40549da0" ?
                getAllUserInvitations(operation, user.uid)
                : getAllUserTags(operation, user.uid)
            setLoader(true)
        } else {
            setLoader(false)
        }

    }, [logged])


    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {

                    case "Get invitations by user ID":
                        setMyInvitations(response.data.data)
                        setLoader(false)
                        break

                    case "Guests by ID":
                        // console.log('my guests: ', response.data)
                        const guests_list = response.data.guest
                        setSharedWith(guests_list.share)
                        setOnShare(true)

                        break;

                    case "Share item added successfully":
                        setSharedWith(response.data.share)
                        setNewShared(null)
                        break;

                    case "Share item removed successfully":
                        setSharedWith(response.data.share)
                        break;

                    default:
                        break;
                }
            }
        }
    }, [response])

    useEffect(() => {
        setOnDate(new Date())
        setIsVisible(true)
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const margin = 0; // Ajusta este valor según tu preferencia


            if (currentScrollPos < margin) {
                setIsVisible(false);
            } else {
                setIsVisible(prevScrollPos > currentScrollPos);

                setPrevScrollPos(currentScrollPos);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onNewShare = () => {

        if (newShared) {
            if (validateEmail(newShared)) {
                addShare(operation, invitationID, newShared);
            } else {
                message.warning('El email debe tener un formato correcto');
            }
        }


    };

    const onDeleteShare = (id) => {
        deleteShare(operation, invitationID, id);
    }

    const handleShareWith = (_id) => {
        if (invitationID === _id) {
            if (onShare) {
                setOnShare(false)
            } else {
                getGuestsByInvitationID(operation, _id)
            }
        } else {
            setOnShare(false)
            getGuestsByInvitationID(operation, _id)
        }

        setinvitationID(_id)
    }

    const getFirstLetterUpperCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    // 




    return (

        <>
            <Layout
                // className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#FFF',
                    // height: '100vh'
                }}>
                <HeaderBuild position={'invitations'} setOpenLogin={setOpenLogin} isVisible={isVisible} />
                <Layout className='scroll-invitation build-invitation-layout responsive-web' style={{
                    // paddingBottom: '30px',
                    marginTop: '80px',
                }} >
                    {
                        loader ?
                            <div style={{
                                height: '80vh', display: 'flex', alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                                // backgroundColor: lighter(MainColor, 0.9)
                            }}>
                                <img src={load} style={{
                                    width: '10%'
                                }} />
                            </div>


                            : <Content className='invitations-main-content'>
                                <div style={{
                                    display: onShare ? 'flex' : 'none',
                                    right: '20px', top: '100px'
                                }} className='on-share-container'>

                                    <div className='on-share-title-container'>
                                        <span className='on-share--title'>Compartir acceso</span>
                                        <Button
                                            onClick={() => setOnShare(false)}
                                            id='gc-cta-buttons'
                                            type='ghost'
                                            icon={<IoCloseSharp size={15} />} />
                                    </div>

                                    <div className='add-new-shared-container'>
                                        <Input placeholder={'Agrega correo'}
                                            style={{ flex: 1 }}
                                            value={newShared}
                                            onChange={(e) => setNewShared(e.target.value)}
                                            className='gc-input-text' />

                                        <Button
                                            onClick={onNewShare}
                                            id='gc-cta-buttons'
                                            type='ghost'
                                            style={{
                                                borderRadius: '10px', marginLeft: '5px',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}
                                            icon={<IoAddCircleOutline size={25} />} />
                                    </div>

                                    <div className='on-share-view-pass-container' style={{ justifyContent: 'flex-start' }}>

                                        <Button
                                            onClick={() => setOnViewPasswords(!onViewPasswords)}
                                            id='gc-cta-buttons'
                                            type='ghost'
                                            icon={onViewPasswords ? <BiHide size={20} /> : <BiShow size={20} />} />
                                        <span className='on-share--label'>Personas que tienen acceso</span>
                                    </div>

                                    <div className='shared-people-container'>
                                        {
                                            sharedWith.map((person, index) => (
                                                <>
                                                    <div key={index} className='shared-person-row-container'>
                                                        <div className='shared-person-row-container' style={{ margin: 0, padding: 0 }}>
                                                            <div className='shared-person-icon'>
                                                                {
                                                                    person ? getFirstLetterUpperCase(person.email)
                                                                        : 'A'

                                                                }
                                                            </div>
                                                            <Col style={{
                                                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column'
                                                            }}>
                                                                <span style={{
                                                                    marginLeft: '8px'
                                                                }} className='on-share-single-label'>{person.email}</span>
                                                                <div style={{
                                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                                                }}>
                                                                    <Button
                                                                        onClick={() => copyToClipboard(person.password)}
                                                                        id='gc-cta-buttons'
                                                                        type='ghost'
                                                                        style={{ marginRight: '5px' }}
                                                                        icon={<FiCopy size={15} />}
                                                                    />
                                                                    <span style={{ width: '100%' }} className='on-share-single-label'>{onViewPasswords ? person.password : '••••••••••'}</span>
                                                                </div>

                                                            </Col>

                                                        </div>

                                                        <Button
                                                            onClick={() => onDeleteShare(person.id)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{ marginRight: '5px', zIndex: '10' }}
                                                            icon={<FaRegTrashCan size={15} />} />
                                                    </div>



                                                </>


                                            ))
                                        }
                                    </div>


                                    <div className='share-copy-container'>
                                        <Button
                                            onClick={() => copyToClipboard(`${baseProd}/share/${invitationID}`)}
                                            icon={<IoMdLink size={22} />}
                                            id='share-copy-button'>Copiar link</Button>
                                    </div>



                                </div>
                                {
                                    logged ?
                                        <div style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                            position: 'relative', padding: '10px',
                                            width: '100%', maxWidth: '1480px', position: 'relative'

                                        }}>
                                            <div className='inv-title-cta-filter'>


                                                <h2 className='module--title inv-title'>Mis Invitaciones</h2>



                                                {/* <div className='inv-filter'></div> */}

                                                <Row style={{
                                                    margin: '30px 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                    gap: '24px'
                                                }}>
                                                    {/* <Link to="https://wa.me/6145394836" target='_blank' style={{

                                                    }}>
                                                        <CustomButton
                                                            secondary={true}
                                                            style={{ color: 'red' }}
                                                            icon={<FaWhatsapp size={15} style={{ color: '#FFF' }} />} text={'Ayuda'}
                                                            styleText={{ fontSize: '20px', marginLeft: '5px', fontWeight: 700 }} />
                                                    </Link> */}


                                                    <CustomButton
                                                        onClick={() => setVisible(true)}
                                                        icon={<IoMdAdd />} text={'Nueva Invitación'} style={{

                                                        }} styleText={{ fontSize: '20px', marginLeft: '5px', fontWeight: 700 }} />

                                                    <div className='grid-layout'>
                                                        <Button
                                                            // disabled={!invitation.active}
                                                            onClick={() => setOnGrid(false)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                margin: '0px',
                                                                color: !onGrid && '#FFB32D'
                                                            }}
                                                            icon={<RiListUnordered size={16} />}
                                                        />

                                                        <div className='grid-layout-divisor' />
                                                        <Button
                                                            // disabled={!invitation.active}
                                                            onClick={() => setOnGrid(true)}
                                                            id='gc-cta-buttons'
                                                            type='ghost'
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                margin: '0px',
                                                                color: onGrid && '#FFB32D'
                                                            }}
                                                            icon={<RxGrid size={16} />}
                                                        />

                                                    </div>


                                                </Row>



                                            </div>

                                            <div className={`inv-invitations-container${onGrid ? '-grid' : ''}`}>
                                                {
                                                    load ? (
                                                        myInvitations?.length > 0 ? (
                                                            myInvitations.map((invitation) => (
                                                                <div
                                                                    key={invitation._id}
                                                                    className={`invitation-container${onGrid ? '-grid' : ''}`}
                                                                >
                                                                    {
                                                                        onGrid &&
                                                                        <>
                                                                            <div
                                                                                className='status-dot'
                                                                                style={{
                                                                                    backgroundColor: invitation.active ? '#FFB32D' : '#F6F2F3',


                                                                                }}
                                                                            >
                                                                                {invitation.active ? 'Activa' : 'Inactiva'}
                                                                            </div>

                                                                            {
                                                                                invitation.active &&
                                                                                <Button
                                                                                    icon={<HiMiniArrowUpRight size={16} />}
                                                                                    onClick={() =>
                                                                                        handleMoode('on-edit', invitation._id)
                                                                                    }
                                                                                    id='ic-edit-button-grid'
                                                                                />
                                                                            }

                                                                        </>
                                                                    }


                                                                    <div className={`invitation-image-container${onGrid ? '-grid' : ''}`}>
                                                                        {
                                                                            invitation.cover.featured_prod && !onGrid ? (
                                                                                <img src={invitation.cover.featured_prod} alt="Featured product" />
                                                                            ) : (
                                                                                <div
                                                                                    className='background-color'
                                                                                    style={{
                                                                                        background: onGrid ? invitation.generals.palette.accent : invitation.cover.background,
                                                                                        opacity: 0.85,
                                                                                    }}
                                                                                />
                                                                            )
                                                                        }
                                                                        <h1
                                                                            style={{
                                                                                fontFamily: invitation.cover.image,
                                                                                fontWeight: invitation.cover.fontWeight,
                                                                                color: invitation.cover.color,
                                                                            }}
                                                                            className='invitation-image-title'
                                                                        >
                                                                            {invitation.cover.title}
                                                                        </h1>
                                                                    </div>

                                                                    <div className='invitation-settings-container'>
                                                                        <Row className='inv-status-container'>
                                                                            <a className='gc--title label'
                                                                                style={{
                                                                                    fontSize: onGrid && '13px'
                                                                                }}
                                                                            >
                                                                                {invitation.generals.eventName}
                                                                            </a>
                                                                        </Row>
                                                                        <div className='icon-deadline-container'>
                                                                            <Button
                                                                                disabled={!invitation.active}
                                                                                onClick={() => handleShareWith(invitation._id)}
                                                                                id='gc-cta-buttons'
                                                                                type='ghost'
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    margin: '0px'
                                                                                }}
                                                                                icon={<RiUserAddLine size={onGrid ? 18 : 20} />} />
                                                                            <Button
                                                                                disabled={!invitation.active}
                                                                                onClick={() => handleQRO(invitation._id)}
                                                                                id='gc-cta-buttons'
                                                                                type='ghost'
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    margin: '0px'
                                                                                }}
                                                                                icon={<FiShare2 size={onGrid ? 18 : 20} />}
                                                                            />

                                                                        </div>
                                                                    </div>

                                                                    {
                                                                        !onGrid &&
                                                                        <div className='invitation-info-container' style={{
                                                                            height: 'auto'
                                                                        }}>


                                                                            <div className='inv-date-progress-container'>
                                                                                {
                                                                                    invitation.payment === 'false' ?
                                                                                        <div className='inv-info-col'>
                                                                                            <span style={{
                                                                                                marginTop: '12px'
                                                                                            }} className='inv-info-col-label'>El acceso a la edición de la invitación está limitado por un pago pendiente, pero no te preocupes, la invitación sigue activa y funcionando para tus invitados.</span>
                                                                                            \
                                                                                        </div> :

                                                                                        invitation.active
                                                                                            ?
                                                                                            <div className='inv-info-col'>
                                                                                                <span className='inv-info-col-label'>Invitación Activa</span>
                                                                                                {/* <div className='icon-deadline-container'>
                                                                                                <span>{getTimeUntilFutureDate(invitation.cover.date)}</span>
                                                                                                <div
                                                                                                    className='state-dot-container'
                                                                                                    style={{ marginLeft: '5px' }}
                                                                                                >
                                                                                                </div>
                                                                                            </div> */}
                                                                                            </div>
                                                                                            :
                                                                                            <div className='inv-info-col'>
                                                                                                <span className='inv-info-col-label'>Esperando activación</span>
                                                                                                {/* <div className='icon-deadline-container'>
                                                                                                <span>--- / ---</span>

                                                                                            </div> */}
                                                                                            </div>
                                                                                }
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    flexDirection: 'row',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    invitation.payment === 'false' ?
                                                                                        <></> :
                                                                                        invitation.active ?
                                                                                            <Button
                                                                                                // icon={}
                                                                                                onClick={() =>
                                                                                                    handleMoode('on-edit', invitation._id)
                                                                                                }
                                                                                                id='ic-edit-button'
                                                                                            >
                                                                                                <span>
                                                                                                    Comenzar
                                                                                                    <RiArrowRightSLine size={25} />
                                                                                                </span>
                                                                                            </Button> :
                                                                                            <></>
                                                                                }

                                                                            </div>


                                                                        </div>

                                                                    }
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    marginTop: '100px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Empty style={{ marginTop: '50px' }} description={false} />
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                marginTop: '150px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={load}
                                                                style={{
                                                                    width: '5%',
                                                                }}
                                                                alt="Loading"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className='shadow--white-right' />
                                            <div className='shadow--white-left' />
                                        </div>

                                        :
                                        <div style={{
                                            width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'flex-start',
                                            paddingTop: '100px', justifyContent: 'center'
                                        }}>
                                            <Login position={'land-page'} setOpenLogin={setOpenLogin} />
                                        </div>
                                }


                            </Content>
                    }



                </Layout>

                <Layout
                    className='responsive-mobile'
                    style={{
                        height: '100vh', backgroundColor: '#FFFFFF',
                        width: '100%'
                    }}
                >

                    <img src={justWeb} style={{
                        objectFit: 'cover', width: '80%'
                    }} />

                </Layout >
                <FooterMobile position={'invitations'} setOpenLogin={setOpenLogin} isVisible={isVisible} />


            </Layout >

            {
                logged && <NewInvitationDrawer visible={visible} setVisible={setVisible} refreshInvitations={refreshInvitations} />
            }

        </>

    )
}
